import { FirstDayOfWeek, MonthType, START_DATE, useDatepicker } from '@datepicker-react/hooks'
import { trackEvent } from '@util/ga'
import { add, addDays, differenceInDays, format, getMonth, getYear, isAfter, isSameDay, startOfMonth } from 'date-fns'
import { useRouter } from 'next/router'
import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react'
import { useSearch } from './search'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from '@contexts/global'
import { ProductType } from '@service/configuration.types'
import { useLocaleConfig } from '@contexts/config'
import { getStartDayOfWeekByCity } from '@util/localization'

type DatePickerContextType = {
    focusedDate: Date | null
    isDateFocused: (date: Date) => boolean
    isDateSelected: (date: Date) => boolean
    isDateHovered: (date: Date) => boolean
    isDateBlocked: (date: Date) => boolean
    isFirstOrLastSelectedDate: (date: Date) => boolean
    onDateFocus: (date: Date) => void
    onDateHover: (date: Date) => void
    onDateSelect: (date: Date) => void
    setModal: any
    activeMonths: MonthType[]
    firstDayOfWeek: FirstDayOfWeek
    handleDateSelection: () => void
    startDate: Date | null
    endDate: Date | null
    focusedInput: 'startDate' | 'endDate' | null
    goToNextMonths: () => void
    goToPreviousMonths: () => void
    closedDays: Date[]
    datePickerTitle?: string
}

const DatePickerContext = createContext<DatePickerContextType>({} as DatePickerContextType)

export const maxNumberOfBookingDays = 180

type FocusedInput = 'startDate' | 'endDate' | null

export const DatePickerProvider: FC<{
    setModal: any
    isReturnDateChange?: boolean
    maxBookingDays?: number
    minBookingDays?: number
    onSave?: (endDate: Date | null) => void
    children: ReactNode
    maxBookingToDate?: Date
    minBookingFromDate?: Date
    isDesktop: boolean
    onlyOneDaySelectable?: boolean
    isSingleDaySelectionMode?: boolean
    datePickerTitle?: string
}> = ({
    children,
    setModal,
    maxBookingDays = maxNumberOfBookingDays,
    minBookingDays = 1,
    isReturnDateChange = false,
    maxBookingToDate = new Date(),
    minBookingFromDate = new Date(),
    onSave,
    isDesktop,
    onlyOneDaySelectable = false,
    isSingleDaySelectionMode = false,
    datePickerTitle,
}) => {
    const { t } = useTranslation()
    const { fromDate, toDate, setFromDate, setToDate, closedDates, initializationByCitySettings } = useSearch()
    const { citySettings, cityCode } = useLocaleConfig()
    const maxStartDateOffset = citySettings?.maxStartDateOffset || 30
    const {
        bookingState: { productType, dateModalType },
    } = useGlobalState()
    const router = useRouter()
    const routeBaseObject = router.asPath.split('?')
    const routeBasePath = routeBaseObject && routeBaseObject[0].slice(1, -1).split('/')
    const title = isSingleDaySelectionMode
        ? t('datepicker.label.monthly', 'Set trip start date')
        : t('datepicker.label', 'Set trip start and end date')

    // Base/Home route
    let baseGALabel = 'Home'
    let baseGACategory = 'HomeDR'
    // cars main route
    if (routeBasePath.length === 2 && routeBasePath[1] === 'cars') {
        baseGALabel = 'Browse'
        baseGACategory = 'BrowseDR'
    }
    // cars listingID route
    if (routeBasePath.length === 3 && routeBasePath[1] === 'cars') {
        baseGALabel = 'Car-details'
        baseGACategory = 'Car-detailsDR'
    }
    const handleDateChange = (data: { startDate: Date; endDate: Date; focusedInput: FocusedInput }): void => {
        const { startDate, endDate, focusedInput } = data
        if (isSingleDaySelectionMode) {
            setStartDate(startDate)
            setEndDate(startDate)
            return
        }
        const closedDayIsSameDay = closedDates.some(
            (closedDay) => isSameDay(closedDay, endDate) || isSameDay(closedDay, startDate),
        )
        if (isReturnDateChange) {
            if (fromDate && differenceInDays(startDate, fromDate) >= 0 && !isSameDay(startDate, fromDate)) {
                setEndDate(startDate)
            }
        } else if (onlyOneDaySelectable) {
            setEndDate(startDate)
            setStartDate(startDate)
        } else {
            sendToGtagStart(data)

            if (closedDayIsSameDay) return

            if (!focusedInput) {
                setEndDate(endDate)
                setStartDate(startDate)
                setFocusedInput(START_DATE)
            } else {
                setStartDate(startDate)
                setEndDate(null)
                setFocusedInput(focusedInput)
            }
        }
    }

    const handleDateSelection = () => {
        initializationByCitySettings()
        setFromDate(startDate)
        setToDate(endDate)
        setModal(false)
        sendToGtagEnd()
        onSave && onSave(endDate)
    }

    const sendToGtagStart = (data) => {
        if (focusedInput === START_DATE && data.startDate) {
            trackEvent({
                action: 'From-date-start',
                category: baseGACategory,
                label: baseGALabel,
                value: format(data.startDate, 'MMM dd'),
            })
        } else if (data.endDate) {
            trackEvent({
                action: 'End-date-start',
                category: baseGACategory,
                label: baseGALabel,
                value: format(data.endDate, 'MMM dd'),
            })
        }
    }

    const sendToGtagEnd = () => {
        if (startDate) {
            trackEvent({
                action: 'From-date-finish',
                category: baseGACategory,
                label: baseGALabel,
                value: format(startDate, 'MMM dd'),
            })
        }
        if (endDate) {
            trackEvent({
                action: 'End-date-finish',
                category: baseGACategory,
                label: baseGALabel,
                value: format(endDate, 'MMM dd'),
            })
        }
    }

    const [startDate, setStartDate] = useState<Date | null>(fromDate)
    const [endDate, setEndDate] = useState<Date | null>(isSingleDaySelectionMode ? fromDate : toDate)
    const [focusedInput, setFocusedInput] = useState<FocusedInput>(START_DATE)

    const numberOfMonths = isDesktop ? 2 : 7

    const {
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToNextMonths,
        goToPreviousMonths,
    } = useDatepicker({
        startDate,
        endDate,
        focusedInput,
        onDatesChange: handleDateChange,
        numberOfMonths: numberOfMonths,
        changeActiveMonthOnSelect: false,
        isDateBlocked:
            dateModalType !== 'endDate' && productType === ProductType.MONTHLY
                ? (date: Date) => {
                      return isAfter(date, addDays(new Date(), maxStartDateOffset))
                  }
                : undefined,
        maxBookingDate: add(maxBookingToDate, { days: maxBookingDays }),
        minBookingDate: add(minBookingFromDate, { days: minBookingDays }),
        firstDayOfWeek: getStartDayOfWeekByCity(cityCode),
        minBookingDays: 1,
    })
    const mobileActiveView = useMemo(() => {
        const updatedActiveMonths: MonthType[] = []
        for (let i = 0; i < numberOfMonths; i++) {
            const date = add(minBookingFromDate, { months: i })
            updatedActiveMonths.push({ year: getYear(date), month: getMonth(date), date: startOfMonth(date) })
        }
        return updatedActiveMonths
    }, [minBookingFromDate, numberOfMonths])

    return (
        <DatePickerContext.Provider
            value={{
                firstDayOfWeek,
                activeMonths: isDesktop ? activeMonths : mobileActiveView,
                isDateSelected,
                isDateHovered,
                isFirstOrLastSelectedDate,
                isDateBlocked,
                isDateFocused,
                focusedDate,
                onDateHover,
                onDateSelect,
                onDateFocus,
                handleDateSelection,
                setModal,
                startDate,
                endDate,
                focusedInput,
                goToNextMonths,
                goToPreviousMonths,
                closedDays: closedDates || [],
                datePickerTitle: datePickerTitle || title,
            }}
        >
            {children}
        </DatePickerContext.Provider>
    )
}

export const useDatePicker = (): DatePickerContextType => useContext(DatePickerContext)
