import { useSearch } from '@contexts/search'
import { availabilityService } from '@service/availability'
import { useMutation, useQuery } from 'react-query'
import { useRouter } from 'next/router'
import { CityCode, DateTimeSelectorTypes } from '@util/enums'
import { format, minutesToMilliseconds } from 'date-fns'
import { useLocaleConfig } from '@contexts/config'
import { useMemo } from 'react'
import { convertTimeSlotToPickerTime } from './utils'

const queryDateFormat = 'yyyy-MM-dd'

export const useTimeSelector = (specTime: DateTimeSelectorTypes, cityCode?: CityCode) => {
    const {
        query: { listingsId, bookingId },
    } = useRouter()
    const { citySettings } = useLocaleConfig()
    const { fromDate, toDate } = useSearch()

    const city = useMemo(
        () => cityCode || (citySettings?.cityCode as CityCode) || CityCode.DXB,
        [cityCode, citySettings?.cityCode],
    )

    const selectedDate = useMemo(
        () => (specTime === DateTimeSelectorTypes.from ? fromDate : toDate),
        [fromDate, specTime, toDate],
    )

    const queryKey = useMemo(
        () =>
            listingsId
                ? ['getTimeSlotsByListingId', listingsId, selectedDate]
                : ['getTimeSlotsByBookingId', bookingId, selectedDate],
        [bookingId, listingsId, selectedDate],
    )

    const { mutateAsync: getTimeSlotsByListingIdMutate } = useMutation({
        mutationFn: (date: Date) =>
            availabilityService.getTimeSlotsByListingId(
                listingsId as string,
                encodeURIComponent(format(date, queryDateFormat)),
                city,
            ),
    })

    const { mutateAsync: getTimeSlotsByBookingIdMutate } = useMutation({
        mutationFn: (date: Date) =>
            availabilityService.getTimeSlotsByBookingId(
                bookingId as string,
                encodeURIComponent(format(date, queryDateFormat)),
                city,
            ),
    })

    const fetchData = useMemo(() => {
        if (!selectedDate) return () => null
        return listingsId
            ? () => getTimeSlotsByListingIdMutate(selectedDate)
            : bookingId
            ? () => getTimeSlotsByBookingIdMutate(selectedDate)
            : () => null
    }, [selectedDate, listingsId, bookingId, getTimeSlotsByListingIdMutate, getTimeSlotsByBookingIdMutate])

    const timeSlotsQuery = useQuery(queryKey, fetchData, {
        staleTime: minutesToMilliseconds(1),
        enabled: !!fetchData && (!!listingsId || !!bookingId),
    })

    const getTimeSlotList = useMemo(
        () => convertTimeSlotToPickerTime(timeSlotsQuery.data?.data?.timeSlotAvailabilities || []),
        [timeSlotsQuery.data?.data?.timeSlotAvailabilities],
    )

    return {
        timeSlotsQuery,
        convertTimeSlotToPickerTime,
        timeSlotList: getTimeSlotList,
    }
}
